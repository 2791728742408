import { extend, configure, setInteractionMode } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

configure({
	classes: {
		valid: "is-valid",
		invalid: "is-invalid",
		dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
	},
});

setInteractionMode("eager");

// with typescript
for (const [rule, validation] of Object.entries(rules)) {
	extend(rule, {
		...validation,
	});
}
