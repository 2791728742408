import "./VeeValidate";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { APIResponse } from "../../services/http";
import { AlertComponentInterface, PuzzleComponentInterface, PuzzleMode } from "./../interfaces";
import StatefulButton from "./../StatefulButton.vue";

@Component({
	components: {
		ValidationProvider,
		ValidationObserver,
		StatefulButton,
	},
})
export class BaseForm extends Vue {
	@Prop({ required: true })
	public readonly action!: string;

	@Ref("formObserver")
	public readonly $formObserver!: InstanceType<typeof ValidationObserver>;

	@Ref("alert")
	public readonly $alert!: AlertComponentInterface;

	@Ref("puzzle")
	public readonly $puzzle!: Vue & PuzzleComponentInterface;

	public disabled = false;
	public busy = false;
	public hasLoader = false;
	public hasPuzzle = true;
	public puzzleField = "puzzle";

	public model: Record<string, any> = {};

	public hasError = false;

	getPuzzleError(error: string) {
		if (this.$puzzle && this.$puzzle.state === "ongoing") {
			return "Anti-Robot verification ongoing! Please wait";
		}
		return error;
	}

	public get puzzleMessage() {
		console.log("Getting Puzzle Message...", this.$puzzle?.state);
		if (!this.hasPuzzle) {
			return {};
		}
		return {
			required:
				this.$puzzle?.state === "ongoing" ? "Anti-Robot verification ongoing! Please wait" : "Anti-Robot verification is required!",
		};
	}

	public recreatePuzzle(mode: PuzzleMode = "none") {
		if (!this.hasPuzzle) {
			return;
		}
		this.$puzzle.recreate(mode);
	}

	public handleError(response: APIResponse) {
		const error = response.error;
		if (this.hasError) {
			this.$alert.clear();
		}
		this.hasError = true;
		this.$alert.addAlert({
			type: "danger",
			message: error.message || "An error occured",
		});
		if (response.validationFailed) {
			// do something
			const errors = response.validationErrors;
			this.$formObserver.setErrors(errors);
			if (this.hasPuzzle && this.$puzzle && errors[this.puzzleField]) {
				this.model[this.puzzleField] = null;
				this.$puzzle.recreate("none");
			}
		}
	}

	public async send() {
		return;
	}

	public async submit() {
		if (this.busy) {
			console.log("Form is already being submitted...");
			return;
		}
		this.busy = true;
		if (this.hasError) {
			this.$alert.clear();
		}
		try {
			await this.send();
		} catch (error: any) {
			this.$alert.addAlert({
				type: "danger",
				message: error.message || "An error occured",
			});
		} finally {
			this.busy = false;
		}
	}

	public async resubmit() {
		if (!this.hasPuzzle) {
			this.submit();
			return;
		}
		this.$puzzle.$once("done", () => {
			this.submit();
		});
		this.recreatePuzzle("auto");
	}
}
